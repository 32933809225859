import type {FeaturesConfigGeneric} from '@/src/lib/features';

export enum Feature {
    ALLOW_EXTERNAL_IDP_USERS_WRITE = 'allow_external_idp_users_write',
    ENABLE_POLICIES = 'enable_policies',
    ALLOW_LANGUAGE_SWITCH = 'allow_language_switch',
    ENABLE_PERMISSIONS = 'enable_permissions',
    ENABLE_ROLES_EXPORT = 'enable_roles_export',
    ENABLE_COURIERS_IMPORT = 'enable_couriers_import',
    ENABLE_COMPANY_BRAND_DOMAIN_REDIRECT = 'enable_company_brand_domain_redirect',
    ENABLE_DRAFTS = 'enable_drafts'
}

export type FeaturesConfig = FeaturesConfigGeneric<Feature>;
