import type {CompanyBrand, IdentitySource} from '@lavka-birds/b2b-common';
import type {IdentityBasketUserKind} from '@lavka-birds/b2b-common';
import type {LanguageCode} from '@lavka-birds/b2b-common';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {UILanguage} from '@/src/i18n';
import type {FeaturesConfig} from 'types/features';
import type {AuthUser} from 'types/login';
import type {Role} from 'types/roles';

interface ClientAppState {
    serverError?: {statusCode: number; errorMessage: string};
    user?: AuthUser;
    csrfToken?: string;
    basketIdentitySource?: IdentitySource;
    userRoles?: Role[];
    userConstructorRolesPermissions?: string[];
    userPermissions?: string[];
    company?: {
        id: string;
        slug: string;
        userKinds: IdentityBasketUserKind[];
        uiLanguages: LanguageCode[];
        isService?: boolean;
    };
    featuresOverride?: Partial<FeaturesConfig>;
    availableUILanguages: UILanguage[];
    sessionId: string;
    staticPath: string;
    title: string;
    baseHost: string;
    brand: CompanyBrand;
}

const initialState: ClientAppState = {} as ClientAppState;

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AuthUser>) => {
            state.user = action.payload;
        },
        setCsrfToken: (state, action: PayloadAction<string>) => {
            state.csrfToken = action.payload;
        }
    }
});
export const {actions, reducer} = appSlice;

export {reducer as appReducer, actions as appActions};
